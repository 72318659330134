// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./components/file_upload"

document.addEventListener("turbo:load", () => {
  // Confirm commit
  const elements = document.querySelectorAll('.confirm-commit')

  elements.forEach((element) => {
    element.addEventListener('click', (event) => {
      const confirmText = element.dataset.confirmText || 'Bekræft'
      const clickedOnce = element.dataset.clickedOnce == "true"

      if (!clickedOnce) {
        event.preventDefault();
        element.innerHTML = confirmText
        element.dataset.clickedOnce = "true"
      }
    })
  })

  loadMainNavbar()
})

const loadMainNavbar = () => {
  const navbarElement = document.querySelector('#main-nav')
  const submenuElement = navbarElement.querySelector('#submenu')
  const mobileMenuElement = navbarElement.querySelector('#mobile-menu')

  const submenuTriggerElement = navbarElement.querySelector('#trigger-submenu')
  const mobileMenuTriggerElement = navbarElement.querySelector('#trigger-mobile-menu')

  if (!submenuTriggerElement || !mobileMenuTriggerElement) {
    return;
  }

  submenuTriggerElement.addEventListener('click', (event) => {
    event.preventDefault();
    submenuElement.classList.toggle('hidden')
  })

  mobileMenuTriggerElement.addEventListener('click', (event) => {
    event.preventDefault();
    mobileMenuElement.classList.toggle('hidden')
  })
}
