import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {}

  show(event) {
    const modal = document.querySelector(event.params.target)
    modal.showModal()
  }

  hide(event) {
    const modal = document.querySelector(event.params.target)
    modal.close()
  }
}

