// https://dev.to/chandrapantachhetri/responsive-react-file-upload-component-with-drag-and-drop-4ef8

import React, { useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel
} from "./file_upload.styles";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 800000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({uploadUrl, destroyUrl, initialFiles, maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES, ...otherProps}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState(initialFiles);

  const uploadFiles = (filesToBeUploaded) => {
    const formData = new FormData();
    const filesArray = convertNestedObjectToArray(filesToBeUploaded);

    filesArray.forEach((file) => {
      formData.append("files[]", file, file.name);
    });

    fetch(uploadUrl, {
      method: "POST",
      body: formData,
    }).then((response) => response.json())
      .then((data) => {
        setFiles(data);
      })
  }

  const destroyFile = (fileId) => {
    fetch(destroyUrl, {
      method: "DELETE",
      body: JSON.stringify({ file_id: fileId }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json())
      .then(() => {
        delete files[fileId];
        setFiles({ ...files });
      })
  }

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const checkFiles = (newFiles) => {
    let checkedFiles = [];

    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        checkedFiles[file.name] = file;
      }
    }
    return { ...checkedFiles };
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;

    if (newFiles.length) {
      let checkedFiles = checkFiles(newFiles);
      uploadFiles(checkedFiles);
    }
  };

  const handleRemoveFile = (fileId) => {
    destroyFile(fileId);
  };

  return (
    <>
      <FileUploadContainer>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <i className="fas fa-file-upload" />
          <span> Upload {otherProps.multiple ? "filer" : "en fil"}</span>
        </UploadFileBtn>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        { Object.keys(files).length > 0 && <span>Valgte filer</span>}
        <PreviewList>
          {Object.keys(files).map((id, index) => {
            let file = files[id];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={id}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={file.url}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <RemoveFileIcon
                        className="bi bi-x-circle-fill"
                        onClick={() => handleRemoveFile(id)}
                      />
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );
};

export default FileUpload;

document.addEventListener("turbo:load", () => {
  const el = document.querySelector('.file-upload');

  if (el) {
    const uploadUrl = el.dataset.uploadUrl;
    const destroyUrl = el.dataset.destroyUrl;
    const files = JSON.parse(el.dataset.files);

    const root = createRoot(el);

    root.render(
      <React.StrictMode>
        <FileUpload
          uploadUrl={uploadUrl}
          destroyUrl={destroyUrl}
          initialFiles={files}
          accept=".jpg,.png,.jpeg"
          multiple
        />
      </React.StrictMode>
    );
  }
});
